<template>
<div class="tabulation">
  <el-card>
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="发行量" prop="name">
          <div class="aaaa">
            <el-input v-model="form.name" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"  placeholder="请填写发行提货券数量" size="large"></el-input>
          </div>
        </el-form-item>
      <!-- 备注 -->
        <el-form-item label="备注" prop="desc">
          <div class="shuoming">
            <el-input type="textarea" v-model="form.desc" placeholder="请填写备注"></el-input>
          </div>
        </el-form-item>


        <el-form-item>
          <div class="submit">
            <el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading" @click="onSubmit">提交</el-button>
          </div>
        </el-form-item>
    </el-form>
    </div>
  
  </el-card>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, reactive } from 'vue'
import {  Plus,} from '@element-plus/icons-vue'
// import * as imageConversion from 'image-conversion'
import { THpublish } from '../../../utils/api'
import qs from 'qs'
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default{
  components:{ Plus, },
  setup(){
    // 按钮加载
    const ConfirmLoading=ref(false)

    // 提交 按钮
    const router = useRouter();
    const onSubmit =()=>{
      ruleFormRef.value.validate((valid)=>{
        console.log(valid)
        console.log('发行积分量',form.name)
        console.log('备注',form.desc)
        if(valid==true){
          ConfirmLoading.value=true
          let data = {
            cashValue:form.name,
            reason:form.desc
          }
          THpublish(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: "添加成功",
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                form.name=''
                form.desc=''
                router.push({ name: 'THticket', params: { userId: 1 }});
                // router.push("/JFpublish")
                ConfirmLoading.value=false
              },500)
              // router.push("/THticket")
              // ConfirmLoading.value=false
            }else if(res.data.code!==0){
              ElMessage({
                message: "提交失败",
                type: "error",
                center: true,
              });
              ConfirmLoading.value=false
            }
          })
        }else{
          // ElMessage({
          //   message: "提交失败",
          //   type: "error",
          //   center: true,
          // });
          ConfirmLoading.value=false
        }
        console.log(valid)
        // console.log(form)
      })
    }
 // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
 
 
    // 模拟 ajax 异步获取内容
    onMounted(() => {
    })
 
    const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {} }
    editorConfig.MENU_CONF['uploadImage'] = {
    // 上传图片的配置
    }
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value 
      if (editor == null) return
      editor.destroy()
    })
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      breviary:""
    })
    // 验证表单提示
    const rules ={
      desc:[
        { required: true, message: "请填写备注", trigger: "blur" },
      ],
      // breviary:[
      //   { required: true, message: "请上传缩略图", trigger: "change" },
      // ],
      name:[
        { required: true, message: "请填写发行提货券数量", trigger: "blur" },
      ],
      // are:[
      //   { required: true, message: "请选择是否发布", trigger: "blur" },
      // ],
    }
    return{
      // 按钮加载
      ConfirmLoading,
      // handlePictureCardPreview,
      // 编辑器
      onSubmit,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .shuoming{
  width: 100%;
}
.el-textarea{
  width: 35%;
}
::v-deep .submit{
  margin-left: 80px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
}
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
.aaaa{
  width: 35%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
::v-deep .el-card.is-always-shadow{
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: auto;
  margin-top: 10px;
}
::v-deep .tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
